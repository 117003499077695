

.profile-image-container {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f3f4f6; 
  display: flex;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  align-items: center;
  justify-content: center;
  
  .profile-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .profile-icon {
    font-size: 2rem;
    color: #6b7280; 
  }
}


.choose-image-button {
  margin-left: 1.25rem;
  background-color: var(--primary) ;
  padding: 0.4rem;
  padding-top: 0.75rem;
  border-radius: 0.375rem;
  color: white;
  font-size: 0.675rem;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }

  &:focus {
    outline: 2px solid #6366f1; 
    outline-offset: 2px;
  }
}
