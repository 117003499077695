@import "./variables";

#tmf_sidebar_container {
    top: 4.3rem !important;
    border-top: 1px solid rgb(235, 235, 235);
    height: calc(100vh - 72px);
    position: fixed;
    top: 0;
    left: 0;
    width: 260px;
    z-index: 100;
    background-color: white;
    transition: all 0.5s ease;

    &.close {
        width: 70px;
        opacity: 1 !important;
    }

    >.screen-view {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100% !important;

        >div {
            width: 100% !important;
            border: 1px solid white;

            >ul {
                >li {
                    a {
                        color: var(--txt-primary);
                        padding-left: 2.5rem;
                        width: 100%;

                        &:hover {
                            background-color: var(--secondary-color) !important;
                            color: var(--white) !important;
                        }
                    }
                }
            }
        }
    }

    >.mobile-view {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100% !important;

        >.mobile-sidebar {
            background-color: white !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.3rem;
            cursor: pointer;

            .dashboard-icon {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 13.5px 0px;

                &:hover {
                    background-color: var(--secondary-color);
                    >a{
                        color: white;
                        svg{
                            transform: scale(1.15);
                        }
                    }
                   
                }
                >a {
                    color: #23005B;
                    width: 100%;
                    padding-left: 25px;
                    background-color: transparent;
                    transition: all 0.3s ease-in-out;

                    svg {
                        transition: transform 0.3s ease-in-out;
                    }
                }

            }

            .icon-wrapper {
                position: relative;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 13.5px 0px;

                &:hover {
                    background-color: var(--secondary-color);

                    svg {
                        color: white !important;
                        transform: scale(1.15);
                    }
                }

                >svg {
                    color: #23005B;
                    transition: transform 0.3s ease-in-out;
                }

                .sub-items {
                    display: none;
                    width: 180px;
                    position: absolute;
                    top: 0px;
                    left: 69px;
                    background-color: white;
                    border: 1px solid white;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                    z-index: 1000;
                    transition: transform 0.3s ease-in-out;
                    border-top-right-radius: 12px;
                    border-bottom-right-radius: 12px;
                    border: none;


                    div {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-weight: 500;
                        font-size: 13px;
                        padding: 17px 16px;
                        >a{
                            color: #23005B;
                            text-decoration: none;
                            width: 100%;
                        }
                   
                        &:hover {
                            background-color: var(--secondary-color);
                          >a{
                            color: white;
                          }
                        }

                        &:first-child:hover {
                            border-top-right-radius: 12px;
                          
                        }

                        &:last-child:hover {
                            border-bottom-right-radius: 12px;
                           
                        }

                     
                    }
                }

                &:hover .sub-items {
                    display: block;
                }
            }


        }

        >.buttons {
            cursor: pointer;
            border-top: 2px solid #C4C4C4 !important;
            width: 95%;
            z-index: 2;
            background-color: white;
            padding-left: 16px;

            >div {
                display: flex;
                align-items: center;
                color: #23005B;
                font-weight: 600;
                height: 2.5rem;

                >svg {
                    height: 20px;
                    width: 20px;
                    transition: all 0.5s ease-in-out;
                }
            }

        }

    }



}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-active {
    background-color: var(--secondary-color) !important;
    color: var(--white) !important;
}

html:not([dir=rtl]) .c-sidebar:not(.c-sidebar-right) {
    margin-left: 0px !important;
}


.logout-button {
    cursor: pointer;
    border-top: 2px solid #C4C4C4 !important;
    width: 95%;
    padding: 0.8445rem 29px;
    z-index: 2;
    background-color: white;


    >div {
        display: flex;
        align-items: center;
        color: #23005B;
        font-weight: 600;
        height: 2.5rem;

        >svg {
            height: 16px;
            width: 16px;
            margin-right: 4px;
        }

        >button {
            font-weight: 500;
            font-size: 14px;
            background-color: white;
            color: #23005B;
            border: none;
            outline: none;
        }
    }
}