.pagination-box{
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
    padding: 28px;

    button{
        border: none;
        border-radius: 50%;
        padding: 10px 15px 13px 15px,
    }

    p{
        font-weight: 600;
        font-size: 16px;
        margin-top: 4px;
    }

}