:root,
.c-app {
  --primary: #1C63B7;
  --secondary-color: #00AEDF;
  --txt-primary: #23005B;
  --txt-secondary: #C4C4C4;
  --dark-blue: #3E00C2;
  --txt-purple:#5321CA;
  --danger: #F93A3A;
  --orange: #EAA215;
  --green: #08D110;
  --body-bg: rgb(204, 204, 204);
  --white: #ffffff;
  --darkWhite: #ccc;
  --black: #333333;
  --dark: #615c60;
  --themeColor: #22b8d1;
  --pageShadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  --shadow-color: 0deg 0% 0%;
  --bg-color:#efefef;
  --gray: #979699;

}

//$primary-color: #0080ff;
$primary-color: #23005B;
$primary-color:#C4C4C4;
$border-radius: .3rem;

$shadow-elevation-low: 1px 1px 1.6px hsl(var(--shadow-color) / 0.09),
  1.6px 1.6px 2.5px -1.3px hsl(var(--shadow-color) / 0.08),
  3.6px 3.6px 5.7px -2.5px hsl(var(--shadow-color) / 0.08);
$shadow-elevation-medium: 1px 1px 1.6px hsl(var(--shadow-color) / 0.07),
  2.5px 2.5px 4px -0.6px hsl(var(--shadow-color) / 0.07),
  4.7px 4.7px 7.4px -1.3px hsl(var(--shadow-color) / 0.07),
  9.4px 9.4px 14.9px -1.9px hsl(var(--shadow-color) / 0.07),
  18.2px 18.2px 28.8px -2.5px hsl(var(--shadow-color) / 0.07);
$shadow-elevation-high: 1px 1px 1.6px hsl(var(--shadow-color) / 0.07),
  4.1px 4.1px 6.5px -0.3px hsl(var(--shadow-color) / 0.07),
  7px 7px 11.1px -0.6px hsl(var(--shadow-color) / 0.07),
  10.6px 10.6px 16.8px -0.8px hsl(var(--shadow-color) / 0.07),
  15.5px 15.5px 24.5px -1.1px hsl(var(--shadow-color) / 0.07),
  22.2px 22.2px 35.1px -1.4px hsl(var(--shadow-color) / 0.07),
  31.6px 31.6px 49.9px -1.7px hsl(var(--shadow-color) / 0.07),
  44.3px 44.3px 70px -2px hsl(var(--shadow-color) / 0.06),
  60.8px 60.8px 96.1px -2.3px hsl(var(--shadow-color) / 0.06),
  82px 82px 129.6px -2.5px hsl(var(--shadow-color) / 0.06);

$z-indexes: (
  "modal": 10000,
);


// COMMON CSS
$PRIMARY : "#0d6efd";
$SECONDARY: "#6c757d";
$DANGER:"#dc3545";
$WARNING:"#ffc107";
$SUCCESS: "#198754";
$INFO:"#0dcaf0";
$DARK:"#212529";
$LIGHT:"#f8f9fa";
$WHITE:"#fff";
// BUTTONS
$BTN_PRIMARY : "#0d6efd";
$BTN_SECONDARY: "#6c757d";
$BTN_DANGER:"#dc3545";
$BTN_WARNING:"#ffc107";
$BTN_SUCCESS: "#198754";
$BTN_INFO:"#0dcaf0";

// LABEL
$LBL_PRIMARY : "#0d6efd";
$LBL_SECONDARY : "#6c757d";
$LBL_DANGER:"#dc3545";
$LBL_WARNING:"#ffc107";
$LBL_SUCCESS: "#198754";
$LBL_INFO:"#0dcaf0";

//SIZE
$XS_SIZE:"0px";
$SMALL_SIZE:"600px";
$DEFAULT_SIZE:"600px";
$MEDIUM_SIZE:"900px";
$LARGE_SIZE:"1200px";
$XL_SIZE:"1536px";

//responsive
$breakpoint-sm: 576px;
$breakpoint-md: 800px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1260px;
$breakpoint-xxl:1400px;
