@use "variables";
@use "mixins";

.avish-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: map-get(variables.$z-indexes, "modal");
  @include mixins.flex-center();
  padding: 1rem;


  &__backdrop {
    position: absolute;
    width: 600px;
    height: 100%;
    background-color: rgb(12 93 104 / 20%);
    backdrop-filter: blur(20px) grayscale(0.2);
    z-index: -1;
    animation: fade-in 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
   
    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  &__backdropg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(12 93 104 / 20%);
    backdrop-filter: blur(20px) grayscale(0.6);
    z-index: -1;
    opacity: 0.6;
 
    // animation: fade-in 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  &__content {
    background-color: #fff;
    width: clamp(400px, 30vw, 80vw);
    height: fit-content;
    max-height: 90vh;
    border-radius: variables.$border-radius;
    display: flex;
    flex-direction: column;
    animation: tilt-in-bottom-1 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    @keyframes tilt-in-bottom-1 {
      0% {
        transform: rotateY(30deg) translateY(300px) skewY(-30deg);
        opacity: 0;
      }
      100% {
        transform: rotateY(0deg) translateY(0) skewY(0deg);
        opacity: 1;
      }
    }
  }

  &.hide-modal {
    .avish-modal__content {
      animation: slide-out-blurred-bottom 0.25s
        cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
    }
    .avish-modal__backdrop {
      animation: fade-out 0.2s 0.05s ease-out both;
      @keyframes fade-out {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }

    @keyframes slide-out-blurred-bottom {
      0% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
      }
      100% {
        -webkit-transform: translateY(1000px) scaleY(2) scaleX(0.2);
        transform: translateY(1000px) scaleY(2) scaleX(0.2);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
      }
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-shrink: 1;
    gap: 0.5rem;
    padding:4px 2px;
    border-bottom: 2px solid #eef1f3;
    &__title {
      flex-grow: 1;
      display: flex;
      gap: 0.5rem;
      padding: 0.5rem 0;
      padding-left: 1rem;
      &__text {
        &__wrapper {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          line-height: 1;
          gap: 0.2rem;
        }

        font-size: 1rem;
        font-weight: 500;
      }

      &__subtitle {
        font-size: 0.7rem;
      }

      &__icon {
        &__wrapper {
          @include mixins.flex-center();
          > * {
            font-size: 1.4rem;
            background: linear-gradient(#2e5d72, #03a9f4);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            @include mixins.size(1.2rem);
          }
        }
      }
    }

    &__close {
      @include mixins.size(2rem);
      @include mixins.flex-center();
      flex-shrink: 0;
      cursor: pointer;
      svg {
        @include mixins.size(1rem);
      }
    }

    &__content {
    }
  }

  &__body {
    flex-grow: 1;
    padding: 1rem;
    // @include mixins.scroll-vertical();

  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    &__content {
      flex-grow: 1;
    }
    &__action {
      @include mixins.flex-center();
      gap: 0.5rem;
      flex-shrink: 1;
    }
  }

  &.loading {
    .avish-modal {
      &__content {
        width: 100px;
        height: 100px;
        padding: 2rem;
        @include mixins.flex-center();
        > .ltb-loader {
          svg {
            @include mixins.size(1.3rem);
          }
        }
      }
    }
  }
}

button.avish-button,
input.avish-button {
  border-radius: variables.$border-radius;
  font-size: 0.8rem;
  border: 1px solid #ccd;
  padding: 0.27em 0.7em;
  font-weight: 500;
  transition: all 0.15s linear;
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
  position: relative;
  overflow: hidden;
  &:hover {
    box-shadow: inset 0 0 50px rgb(21 74 119 / 24%);
  }
  &:focus {
    outline: 1px dashed #8aadc8;
    outline-offset: -3px;
  }

  &.primary {
    background: var(--primary);
    color: #fff;
    border: none;
    &:focus {
      outline-color: #fff;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  .ltb-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgb(5 16 21 / 82%);
    @include mixins.flex-center();
    z-index: 1;
    color: #fff;
  }
}


.subscription-modal-body{
  width: 50vw !important;
}

.modal-content{
  width: 50vw !important;
}

.modal-dialog{
  width: 100vw !important;
}

