.tag-search-box{
    position: absolute;
    z-index: 20;
    margin-top: 0.5rem;
    width: 95%;
    background-color: white;
    border-color: #6b7280;
    border-radius: 0 0 10px 10px;
    padding: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    >.search-box{
        max-height: 16rem;
        overflow-y: auto;
      
    }
}

.selected-tag{
    background-color: #e6f0f7;
    color: rgb(31 41 55);
    padding: 0.25rem 0.5rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.1rem;
    gap: 0.3rem;
}

.selected-tag span{
    font-size: 12px;
    font-weight: 400;
}

.selected-tag button{
    color: rgb(220 38 38);
    outline: none;
    border: 1px solid rgb(221, 221, 221);
    padding:0.1rem 0.4rem;
    border-radius: 5px;
}
.selected-tag button:hover{
    color: rgb(153 27 27) !important;
}

.element-with-red-highlight {
    background-color: #FEE2E2; 
    border: 2px solid #EF4444; 
    border-radius: 0.25rem; 
  }

.hidden{
    visibility: hidden;
}