@use "./mixins.scss";

.login-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  >.login-container {
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    padding: 3.75rem;
    text-align: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 50px;

    >.logo {
      display: flex;
      justify-content: center;

      >img {
        width: 5rem;
        height: 4.5rem;
        max-width: 100%;
      }
    }

    >.title {
      font-weight: 600;
      padding: 0 !important;
      line-height: 1rem;

      >h1 {
        font-size: 1.3rem !important;
      }
    }

    >.footer-login {
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      margin-top: 2.5rem;
      width: 100%;

      >.footer-right {
        display: flex;
        align-items: center;
        width: auto;

        >a {
          display: flex;
          align-items: center;
          color: rgb(85, 85, 85);
          >div{
            img{
              flex-shrink: 0;
              width: 1.75rem;
            }
          }
        }
      }
    }

  }

  &__dev-login {

    input[type="email"],
    input[type="password"] {
      border: 1px solid #d8ddf0;
      border-radius: 6px;
      font-size: 15px;
      padding: 0 9px 0 9px;
      height: 2rem;
      transition: border-color 250ms ease-in;

      &:focus {
        border-color: #4c70ff;
        outline: none;
      }

      &:focus+i {
        color: #4c70ff;
      }
    }

    input {
      width: 100%;
    }

    ::placeholder {
      color: #a4a9b6;
      font-size: 12px;
      opacity: 1;
    }
  }
}