strong,
span,
p {
  word-break: break-all;
  white-space: normal;
}

table {
  >thead {
    >tr {
      >th {
        font-size: 12px;
        border-bottom: 1px solid #ddd;
        border-top: 1px solid #aaa;
      }
    }

  }

  >tbody {
    >tr {
     > td {
        font-size: 12px;
        // border: 1px 0 1px 0 solid #ccc;
        border-bottom: 1px solid #ddd;
        border-top: 1px solid #aaa;
        font-weight: 500;
      }

    }
  }
}

p {
  margin-bottom: 4px !important;
}

.org_phone,
.org_mail>span {
  font-size: 12px;
}