.dashboard {
    display: flex;
    flex-direction: row;
    padding: 22px;
    width: 100%;
    gap: 1.375rem;
    background-color: var(--bg-color);
    height: 100%;


    >.graph {
        width: 75%;
        display: flex;
        flex-direction: column;

        >.display-graph {
            margin-bottom: 20px;
            background-color: #fff;
            border-radius: 20px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            height: 50%;
            display: flex;
            padding: 2.0025rem;
            flex-direction: column;


            >.graph-top {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-shrink: 0;
                // padding: 1rem 0.7rem 0 0;
                height: 20%;
                border-radius: 20px 20px 0 0;

                >.lead-box {
                    border-radius: 20px 0 0 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 0.2rem;

                    >.lead-count {
                        display: flex;
                        flex-shrink: 0;
                        font-size: 28px;
                        font-weight: 600;
                        color: var(--txt-primary)
                    }

                    >.lead-text {
                        font-size: 15px;
                        font-weight: 500;
                        color: var(--primary);
                    }
                }

                >.filter {
                    display: flex;
                    gap: 15px;
                    align-items: center;


                    >.lead-icon {
                        display: flex;
                        gap: 10px;
                        color: var(--gray);
                        font-size: 13px;
                        font-weight: 400;
                        display: flex;
                        align-items: center;
                        justify-content: center;


                        >p {
                            font-size: 14px;
                            padding-top: 4px;
                            color: #979699;
                        }
                    }

                    .filter-drop {
                        position: relative;
                        display: inline-block;

                        .filter-input {
                            appearance: none;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            color: #979699;
                            padding: 10px 30px 10px 12px;
                            border-radius: 4px;
                            font-weight: 500;
                            font-size: 0.95rem;
                            height: 40px;
                            width: 100%;
                            border: 1px solid rgba(151, 150, 153, 0.3);
                            background-color: #fff;
                            transition: all 0.3s ease;
                            cursor: pointer;

                            &:focus {
                                outline: none;
                                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                            }

                            &:hover {
                                border-color: #858484;
                                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                            }


                            option {
                                font-weight: 500;
                                font-size: 0.95rem;
                                
                                background-color: #fff;
                                color: #979699;
                           
                                border-radius: 2px !important;
                                cursor: pointer;

                                &:hover,
                                &:focus {
                                    background-color: #f0f0ff;
                                    color: #6c63ff;
                                    cursor: pointer;
                                }

                                &:checked {
                                    background-color: #d1d1d1;
                                    color: #979699;
                                    font-weight: 600;
                                }

                                &:disabled {
                                    color: #ccc;
                                }
                            }
                        }



                        &::after {
                            content: '\25BC';
                            position: absolute;
                            top: 50%;
                            right: 12px;
                            transform: translateY(-50%);
                            pointer-events: none;
                            font-size: 0.8rem;
                            color: #979699;
                        }
                    }
                }
            }

            >.graph-bottom {
                height: 80%;
                border-radius: 0 0 20px 20px;

                >canvas {
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }

        >.leads {
            background-color: #fff;
            height: 50%;
            overflow-y: auto;
            border-radius: 20px;
            padding: 2.0025rem;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

            >.tab-content{
                >.view {
                    @include flex($justifycontent: space-between);
                    border-radius: 20px 20px 0 0;
                    height: 20%;
                    padding-top: 15px;
    
                    >.lead {
                        width: 80%;
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
    
                        >.lead-title {
                            font-size: 1.75rem;
                            color: var(--txt-primary);
                            font-weight: 600;
                        }
    
                        >.lead-para {
                            font-weight: 500;
                            font-size: 0.9375rem;
                            color: var(--primary);
                        }
                    }

                    >.subscription{
                        width: 100%;
                        display: flex;
                        gap: 8px;
                        justify-content: space-between;
    
                        >.lead-title {
                            font-size: 1.75rem;
                            color: var(--txt-primary);
                            font-weight: 600;
                        }
    
                        >.lead-para {
                            font-weight: 500;
                            font-size: 0.9375rem;
                            color: var(--primary);
                            >.nav{
                                >.nav-item{
                                    >.nav-link{
                                        font-size: 10px !important;
                                        color: #768192 !important;
                                    }
                                    >.nav-link.active{
                                        color:  var(--primary) !important;
                                    }
                                }
                              
                            }
                        }
                    }
    
                    >.view-all {
                        flex-shrink: 0;
                        @include flex($alignitem: center);
                        cursor: pointer;
                        font-size: 0.875rem;
                        font-weight: 600;
                        gap: 10px;
                        color: var(--txt-primary);
                        transition: all 0.2s linear;
                        > button {
                            border: none;
                            background-color: transparent;
                            color: var(--txt-primary);
                            font-size: 0.875rem;
                            font-weight: 600;
                            padding: 0;
                            cursor: pointer;
                            transition: all 0.2s linear;
                            outline: none;
                        }
    
                        >svg {
                            font-size: 10px;
                        }
    
                        &:hover {
                            font-weight: 700;
                        }
                    }
                }
    
                >.lead-table {
                  
                    display: flex;
                    overflow-y: scroll;
                    height: 75%;
                    margin-top: 0.5rem;
                    &::-webkit-scrollbar {
                        width: 3px !important;
                    }
    
                    >.table-responsive {
                        &::-webkit-scrollbar {
                            width: 3px !important;
                        }
    
                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: #f1f1f1;
                        }
    
                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: #888;
                            border-radius: 5px;
                        }
    
                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: #555;
                        }
                    }
    
                }
            }
          
        }
    }

    >.count {
        display: flex;
        justify-content: start flex;
        gap: 20px;
        flex-direction: column;
        width: 35%;
        min-width: auto !important;
      

        >.firstbox {
            @include flex();
            flex-direction: column;
            gap: 20px;
            height: 50%;

            >.subscription,
            >.user {
                display: flex;
                flex-direction: column;
                flex-shrink: 0;
                background-color: #fff;
                border-radius: 20px;
                gap: 1.5rem;
                height: 47%;
                padding:2.0025rem;


                >.display-count {
                    border-radius: 20px 20px 0 0;
                    @include flex($alignitem: center);
                    flex-direction: row;
                    gap: 2rem;
                    height: 80%;
                    >.icon {
                        width: 72px;
                        height: 72px;
                        flex-shrink: 0;
                        >img{
                            width: 100%;
                        }
                    
                    }

                    >.detail {
                        display: flex;
                        flex-direction: column;

                        >.count-title {
                            color: var(--txt-primary);
                            font-size: 1.9375rem;
                            font-weight: 600;
                        }

                        >.sub-para {
                            font-size: 1.2rem;
                            color: var(--txt-purple);
                            font-weight: 600;
                        }

                        >.user-para {
                            font-size: 1.2rem;
                            color: var(--green);
                            font-weight: 600;
                        }
                    }
                }

                >.parent-active {
                    border-radius: 5px;
                    gap: 2px;
                    font-weight: 600;
                    @include flex($alignitem: center, $justifycontent: space-between);
                    flex-wrap: wrap;
                    height: 20%;

                    >div {
                        @include flex(center, center);
                        gap: 3px;
                        .active,
                        .inactive,
                        .deleted {
                            color: #454545;
                            font-size: 0.8rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }
                }

            }
        }

        >.organization {
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            background-color: #fff;
            border-radius: 20px;
            gap: 1.5rem;
            height: 25%;
            padding:2.0025rem;

            >.display-count {
                border-radius: 20px 20px 0 0;
                @include flex($alignitem: center);
                flex-direction: row;
                gap: 2rem;
                height: 80%;
                >.icon {
                    width: 72px;
                    height: 72px;
                    flex-shrink: 0;
                    >img{
                        width: 100%;
                    }
                }

                >.detail {
                    display: flex;
                    flex-direction: column;

                    >.count-title {
                        color: var(--txt-primary);
                        font-size: 1.9375rem;
                        font-weight: 600;
                    }

                    >.org-para {
                        font-size: 1.2rem;
                        color: var(--primary);
                        font-weight: 600;
                    }
                }
            }

            >.parent-active {
                border-radius: 5px;
                gap: 5px;
                font-weight: 600;
                @include flex($alignitem: center, $justifycontent: space-between);
                flex-wrap: wrap;

                >div {
                    @include flex(center, center);
                    gap: 3px;
                    .active,
                    .inactive,
                    .deleted {
                        color: #454545;
                        font-size: 0.8rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }
            }

        }
    }
}

.nav-link{
    color: #768192 !important;
}
.nav-link.active{
    color:  var(--primary) !important;
}