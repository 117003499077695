@import "common";

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex($alignitem:"stretch",$justifycontent:"flex-start"){
  display: flex;
  align-items: $alignitem;
  justify-content:$justifycontent;
}

@mixin size($height, $width: "") {
  height: $height;
  @if ($width == "") {
    width: $height;
  } @else {
    width: $width;
  }
}

@mixin scroll-vertical {
  @extend .scroll-vertical;
  transition: all 0.2s ease-in-out;
}


//responsive
@mixin sm {
  @media (max-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin xl {
  @media (max-width: #{$breakpoint-xl}) {
    @content;
  }
}

@mixin xxl {
  @media (max-width: #{$breakpoint-xxl}) {
    @content;
  }
}



