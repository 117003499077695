.c-avatar {
    color: #fff;
}

.sidebar-open-class{
    z-index: 1000 !important;
}

.sidebar-close-class{
    z-index: 1000 !important;
}

.header{
    height: 70px;
    min-height: auto !important;
    >div{
        padding:0.5rem;
        justify-content:space-around;
        gap:2rem;
        align-items:center;
        transition: all 0.3s ease-in-out;
       
        >img{
            height:50px;
            width:100%;
        }
    }
}

.marginleft{
    margin-left:30px;
}

.responsive-marginleft{
    margin-left: 10px !important;
}