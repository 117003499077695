.icon-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    padding: 2.0025rem 2.0025rem 1.00125rem 2.0025rem;
    background-color: #fff;

    >div {
        display: flex;
        align-items: center;
        gap: 30px;

        >.page-title {
            color: var(--txt-primary);
            font-size: 1.5rem;
            font-weight: 600;
        }

        >.icon-create {
            font-size: 0.875rem;
            font-weight: 600;
            color: var(--txt-primary);
            border: none;
            background-color: transparent !important;
            outline: none !important;
            transition: text-shadow 0.3s ease;

            &:hover {
                text-shadow: 0 0 0px var(--txt-primary), 0 0 0px var(--txt-primary), 0 0 20px var(--txt-primary);
            }
        }
    }
}

.icon-search {
    border: 1px solid #C4C4C4;
    border-radius: 12px;
    color: var(txt-secondary);
    position: relative;
    padding: 12px 8px 9px 0px;
    >button{
        background-color: white;
        color: #434343;
        border-radius: 0px 4px 4px 0px;
        margin-top:-3px;
        >svg{
            height: 16px;
            width: 16px;
        }
    }
    >input{
        height: 20px;
        border-radius:4px 0px 0px 4px ;
        outline: none;
        border: white;
        padding-right: 10px;
    }

    >svg{
        cursor: pointer;
        height: 20px;
        width: 20px;
    }
}

.icon-selector{
    >div{
        >.icon-display{
            display: flex;
            align-items: center;
            justify-content: center;
            >.icon{
                height: 1.8rem;
                width: 2rem;
                >img,span{
                    width: 100% !important;
                    height: 100% !important;
                    >svg{
                        margin: 0 !important;
                        height: 100% !important;
                        width: 100% !important;
                    }
                }
             
            }
            >svg{
                height: 1.8rem;
                width: 1.8rem;
            }
        }
    }
}

.icon-box{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.flex-two-row{
    display: flex;
    width: 100%;
    align-items: center;
    >.icon-selector{
        width: 20%;
    }

    >.form-title{
        width: 80%;
    }
}