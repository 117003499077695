@use "./mixins.scss";

.tmf-datatable {
  &__wrapper {
    .table-responsive {
      overflow-y: visible !important;
      @extend .scroll-horizontal;
      @extend .scroll-overlay;
      min-height: 300px;
      &::-webkit-scrollbar-thumb {
        background-color: #ddd;
      }
    }


    .datatable-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include mixins.flex-center();
      background-color: rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      z-index: 100;
      border-radius: 6px;
      overflow: hidden;
    }

    .c-datatable-filter {
      label {
        display: none;
      }
    }

    .form-control {
      height: 1.8rem;
      font-size: 0.8rem;
      padding: 0 0.5rem;
    }

    nav {
      li {
        @include mixins.flex-center();
        @include mixins.size(1.4rem);

        a,
        span {
          padding: 0;
          height: 100%;
          width: 100%;
          @include mixins.flex-center();
          line-height: 1;
          font-size: 0.8rem;
        }

        &.disabled {
          cursor: not-allowed;
          opacity: 0.6;
        }
      }
    }
  }
}

.table-filter-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 12px;
  background-color: #fff;

  >div {
    div {
      div {
        >.exportcsv {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid var(--primary, #1C63B7);
          border-radius: 25px 0px 0px 25px;
          background: rgb(255, 255, 255);
          padding: 8px 25px 8px 12px;
          outline: none;
          color: var(--txt-primary);
          cursor: default;
        }

        >.exportcsvwithborderradius{
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid var(--primary, #1C63B7);
          border-radius: 25px 25px 25px 25px;
          background: rgb(255, 255, 255);
          padding: 8px 25px 8px 12px;
          outline: none;
          color: var(--txt-primary);
          cursor: default;
        }

        >.open-button {
          border-radius: 0px 25px 25px 0px;
          border-top: 1px solid var(--primary, #1C63B7);
          border-right: 1px solid var(--primary, #1C63B7);
          border-bottom: 1px solid var(--primary, #1C63B7);
          border-left: none;
          padding: 6px 8px 6px 4px;
          background: white;
        }
      }

      .dropdownmenubox {
        display: inline-block;
        padding: 10px;
        min-width: 150px; /* Ensure minimum width */
        width: auto; /* Automatically adjusts to content */
        white-space: nowrap;
        >span {
          >a {
            font-size: 14px ;
            display: block;
            white-space: nowrap; 
          }
        }
        >div{
          display: block;
          white-space: nowrap; 
         
          &:hover{
            text-decoration: underline;
          
          }
        }

      }
    }
  }
}

.table {
  background-color: #fff;
  border-radius: 6px;

  >thead {
    >tr {
      border-top: none !important;

      >th {
        border-top: none !important;

        >div {
          font-size: 1rem;
          font-weight: 500;
          line-height: 1;
          display: inline-block !important;
        }
      }


    }


    svg {
      @include mixins.size(0.6rem);
      fill: #388e3c;
    }
  }


  >tbody {
    >tr {
      border-bottom: 1px solid #ddd;

      &:hover {
        background-color: rgb(47 117 173 / 11%);
      }

      >td {
        border-top: none !important;
        border-bottom: none !important;
        font-size: 0.8rem;
        color: #5d6c77;
        vertical-align: middle;

        >.name {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 1rem;
        }

        >.detail,
        >.address {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }

        .dropdown {
          @include mixins.flex-center();
        }
      }
    }
  }



  .datatable-nocontent {
    @include mixins.flex-center();
    flex-direction: column;
    padding: 1rem;
    opacity: .7;
    gap: 1rem;

    svg {
      @include mixins.size(6rem, auto);
    }

    &__message {
      font-size: 0.8rem;
    }
  }
}


.record {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 3px;

  >.record-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space;
    gap: 1rem;
    padding: 5px;

    >div {
      color: var(--txt-primary, #23005B);
      text-align: center;
      font-size: 1rem;
      font-weight: 600;

      >span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        font-weight: 600;
      }
    }
  }


  >.check-box {
    display: flex;
    flex-direction: row;
    gap: 4px;

    .checkbox-break {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;

      >div {
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        justify-content: flex-start;

        >.checkbox-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;
          margin-top: 6px;
        }
      }
    }

    >.checkbox-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      margin-top: 6px;
    }
  }
}




.no-focus-outline:focus {
  outline: none;
}