@media screen and (max-width:991px) {
    .full-width {
        width: 100% !important;
    }

    #tmf_sidebar_container {
        &.close {
            width: 50px;
        }
    }

    .close-body {
        width: calc(100vw - 50px) !important;
    }

}

@include sm {
    .dashboard {

        >.count {

            >.firstbox {
                flex-direction: column;
                height: 100% !important;
                width: 100% !important;

                >.subscription,
                .user {
                    flex: 100%;
                    height: 100% !important;

                    >.display-count {
                        flex-direction: row !important;
                    }
                }


                @include sm {
                    flex-direction: column !important;

                    >.subscription {
                        flex: 100% !important;
                    }

                    >.user {
                        flex: 100% !important;
                    }
                }
            }

            >.organization {
                flex: 100% !important;
            }
        }
    }
}

//Dashboard
@include md {
    .dashboard {
        flex-direction: column !important;

        >.graph {
            width: 100% !important;
        }

        >.count {
            width: 100% !important;

            >.firstbox {
                flex-direction: row;
                height: 100%;

                >.subscription,
                .user {
                    flex: 50%;
                    height: 100% !important;

                    >.display-count {
                        flex-direction: row !important;
                    }
                }


            }

            >.organization {
                flex: 100% !important;
            }
        }
    }
}

@include xxl {
    .dashboard {
        >.count {
            >.firstbox {
                height: 50%;

                >.subscription,
                >.user {
                    gap: 0.5rem !important;

                    >.display-count {
                        height: 100% !important;

                        >.icon {
                            width: 52px !important;
                            height: 52px !important;
                        }

                        >.detail {
                            >.count-title {
                                font-size: 1.5rem !important;
                            }

                            >.sub-para {
                                font-size: 1rem !important;
                            }

                            >.user-para {
                                font-size: 1rem !important;
                            }
                        }
                    }
                }
            }


            >.organization {
                >.display-count {
                    >.icon {
                        width: 52px !important;
                        height: 52px !important;
                    }

                    >.detail {
                        >.count-title {
                            font-size: 1.5rem !important;
                        }

                        >.org-para {
                            font-size: 1rem !important;
                        }
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 815px) {
    .court {
        flex-direction: column !important;
        height: 100vh !important;

        >.category-page{
            width: 100% !important;
            height: 50% !important;
        }
        >.link-page{
            width: 100% !important;
            height: 50% !important;
        }
    }
}

@media (max-width: 992px) and (max-width: 991.98px) {
    .c-sidebar {
        --is-mobile: false !important;
        position: relative !important;
        z-index: 0 !important;

    }


}

@media screen and (min-width: 992px) {
    .c-sidebar-fixed {
        position: relative !important;
        z-index: 0 !important;
    }

    .c-sidebar {
        flex: 3;
    }
}

/* Extra large devices (≥1200px) */
@media screen and (max-width: 1600px) {

    //header
    .header {
        height: 45px !important;

        >.c-header-fixed {
            z-index: 1029 !important;
        }

        >div {
            height: 100% !important;

            >img {
                height: 34px !important;
                width: 100%;
            }
        }

        >.c-header-nav {
            min-height: auto !important;
            height: 100% !important;

            >.c-header-nav-items {
                >div {
                    >.c-avatar {
                        width: 30px !important;
                        height: 30px !important;
                        font-size: 12px !important;
                    }

                    >svg {
                        height: 0.8em !important;
                        width: 0.8em !important;
                    }
                }
            }
        }


    }

    //sidebar
    #tmf_sidebar_container {
        width: 240px;
        top: 2.7rem !important;
        height: calc(100vh - 42px);

        &.close {
            width: 60px;
        }



        >.mobile-view {
            >.mobile-sidebar {

                .dashboard-icon {
                    padding: 0 !important;
                    height: 2.5rem !important;

                    >a {
                        padding-left: 20px !important;
                    }
                }

                .icon-wrapper {
                    padding: 0 !important;
                    height: 2.5rem !important;

                    .sub-items {
                        left: 60px !important;

                        div {
                            padding: 13.5px 16px !important;


                        }
                    }
                }
            }

            >.buttons {
                >.collapse {
                    >svg {
                        height: 16px !important;
                        width: 12px !important;

                    }
                }

                >.logout {
                    >svg {
                        height: 16px !important;
                        width: 16px !important;

                    }
                }

            }

        }

    }

    .close-body {
        width: calc(100vw - 60px) !important;
    }

    .c-sidebar-nav-link {
        height: 2.5rem !important;
        font-size: 12px !important;
    }

    @media screen and (min-width:991px) and (max-width: 1600px) {
        .open-body {
            width: calc(100vw - 240px) !important;
        }
    }

    //body
    .c-wrapper {
        top: 2.7rem !important;


        >.c-body {
            padding-top: 0 !important;

            >.dashboard {
                padding: 13px !important;

                >.graph {
                    >.display-graph {
                        padding: 12px !important;

                        >.graph-top {
                            >.lead-box {
                                >.lead-count {
                                    font-size: 20px !important;
                                }

                                >.lead-text {
                                    font-size: 14px !important;
                                }
                            }

                            >.filter {
                                >.lead-icon {
                                    >p {
                                        font-size: 12px !important;
                                    }
                                }

                                >.filter-drop {
                                    >.filter-input {
                                        padding: 2px 24px 2px 8px !important;
                                        font-size: 0.7rem !important;
                                        height: 31px !important;
                                        width: 96% !important;

                                        option {
                                            font-size: 0.7rem !important;
                                        }
                                    }

                                    &::after {
                                        font-size: 0.6rem !important;
                                    }

                                }
                            }
                        }
                    }

                    >.leads {
                        padding: 13px !important;
                        >.tab-content{
                            >.view {
                                >.lead {
                                    >.lead-title {
                                        font-size: 20px !important;
                                    }
    
                                    >.lead-para {
                                        font-size: 14px !important;
                                    }
                                }
    
                                >.view-all {
                                    font-size: 14px !important;
                                }
                            }
                        }
                     
                    }


                }

                >.count {
                    >.firstbox {

                        >.subscription,
                        .user {
                            padding: 13px !important;

                            >.display-count {
                                >.icon {
                                    width: 60px !important;
                                    height: 60px !important;
                                }

                                >.detail {
                                    >.count-title {
                                        font-size: 20px !important;
                                    }

                                    >.user-para,
                                    .sub-para {
                                        font-size: 16px !important;
                                    }
                                }
                            }

                            >.parent-active {
                                >div {
                                    >span {
                                        font-size: 12px !important;
                                    }
                                }
                            }
                        }
                    }

                    >.organization {
                        padding: 13px !important;

                        >.display-count {
                            >.icon {
                                width: 60px !important;
                                height: 60px !important;
                            }

                            >.detail {
                                >.count-title {
                                    font-size: 20px !important;
                                }

                                >.org-para {
                                    font-size: 16px !important;
                                }
                            }
                        }

                        >.parent-active {
                            >div {
                                >span {
                                    font-size: 12px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //Profile Dropdown
    .profile {
        >.profile-title {
            font-size: 14px !important;
            padding: 8px !important;

            >.profile-name {
                >span {
                    line-height: 21px !important;
                }
            }
        }

        >.profile-desc {
            font-size: 12px !important;
            line-height: 21px !important;
            gap: 3px !important;
            padding: 5px 15px 0px 10px !important;
        }

        >.social-media-links {
            display: flex;
            justify-content: space-around;
            gap: 10px;
            padding: 10px;

            >a {
                height: 30px !important;
                width: 30px !important;

                svg {
                    color: white;
                    width: 20px !important;
                    height: 20px !important;
                    padding-right: 0 !important;
                }
            }
        }

        >.info {
            gap: 15px !important;
            font-size: 10px !important;

            >a {
                >small {
                    font-size: 10px !important;
                }
            }

            >.logout-btn {
                font-size: 10px !important;
            }
        }
    }

    .dropdown-toggle-icon {
        top: 15px !important;
    }




    //common
    //table{
    .tmf-table_wrapper {
        padding: 13px 13px 13px 18px !important;
    }

    .tmf-datatable__wrapper {
        padding: 13px !important;
    }

    //filter-box
    .filter {
        padding: 7.5px 13px 13px 22px !important;

        >div {
            label {
                font-size: 12px !important;
            }

            .select-wrapper {

                select {
                    font-size: 12px !important;
                }
            }


            >.date-range {
                >input {
                    font-size: 12px !important;
                }
            }
        }
    }

    //table filter-box
    .table-filter-section {
        padding: 0px !important;

        >div {
            div {
                div {
                    >.exportcsv {
                        font-size: 12px !important;
                        padding: 5px 7px 4px 7px !important;

                    }

                    >.exportcsvwithborderradius {
                        font-size: 12px !important;
                        padding: 7px 9px 6px 9px !important;
                    }



                    >.open-button {
                        font-size: 12px !important;
                        padding: 5px 7px 4px 7px !important;

                    }

                }

                .dropdownmenubox {
                    font-size: 12px !important;

                    >span {
                        >a {
                            font-size: 12px !important;
                        }
                    }

                    >div {
                        >div {
                            font-size: 12px !important;
                        }
                    }
                }
            }
        }
    }

    //date range
    .dateRangeRef {
        div {
            fieldset {
                .tmf-form-group {
                    label {
                        font-size: 12px !important;
                    }

                    input {
                        font-size: 12px !important;
                    }

                }
            }
        }
    }

    .title,
    .leads-title {
        padding: 15px 13px 7.5px 24px !important;

        >.page-title {
            font-size: 20px !important;
            font-weight: 600;
        }

        >div {
            >.create {
                font-size: 14px !important;
            }
        }
    }

    .icon-title {
        padding: 15px 13px 7.5px 24px !important;

        >div {

            >.page-title {
                font-size: 20px !important;
                font-weight: 600;
            }

            >.icon-create {
                font-size: 14px !important;
            }
        }
    }

    //table
    .table {
        >thead {
            >tr {
                >th {
                    >div {
                        font-size: 12px !important;
                    }
                }
            }
        }

        >tbody {
            >tr {
                >td {
                    font-size: 12px !important;

                    >span {
                        font-size: 12px !important;
                    }

                    >.name {
                        >span {
                            font-size: 15px !important;

                            >span {
                                font-size: 12px !important;
                            }
                        }
                    }

                    >.detail {
                        >span {
                            font-size: 12px !important;
                        }
                    }

                    >.address {
                        >span {
                            font-size: 14px !important;
                        }
                    }

                    .record {
                        >.record-item {
                            padding: 2px;

                            >div {
                                font-size: 16px !important;

                                >span {
                                    font-size: 16px !important;
                                }
                            }
                        }
                    }


                }
            }
        }
    }

    //button
    .apply {
        width: 100px !important;
        height: 35px !important;
        font-size: 12px !important;
        font-weight: 500 !important;
    }

    .clear {
        width: 100px !important;
        height: 35px !important;
        font-size: 12px !important;
    }

    //logout button
    .logout-button {
        padding: 0 29px !important;

        >.collapses {
            >svg {
                height: 16px !important;
                width: 12px !important;

            }
        }

        >.logout {
            >svg {
                height: 16px !important;
                width: 16px !important;

            }
        }

        >div {

            >button {
                font-size: 12px !important;
            }
        }
    }

    //search
    .search {
        padding: 6px 6px 5px 1px !important;

        >input {
            font-size: 12px !important
        }
    }

    //pagination
    .pagination-box {
        padding: 10px !important;

        button {
            border: none;
            border-radius: 50%;
            padding: 4px 7px 5px 9px !important;
        }

        p {
            font-weight: 600;
            font-size: 13px !important;
            margin-top: 4px;
        }

    }

    input {
        font-size: 12px !important;
    }

    select {
        font-size: 12px !important;
    }

    //Court Page
    .court {

        >.category-page,
        >.link-page {
            >.cat-page {
                padding: 13px !important;

                >.court-title {
                    >h1 {
                        font-size: 20px !important;
                    }
                }
            }
        }
    }


    //dnd
    .dnd-section {
        >.title-display {
            >.togglebtn {
                height: 0.7rem !important;
                width: 0.7rem !important;

                &:hover {
                    transform: scaleX(1.1) !important;
                }
            }

            >.name {
                font-size: 12px !important;
            }
        }

        >.action-buttons {
            >span {
                >svg {
                    height: 1rem !important;
                    width: 1rem !important;
                }
            }
        }
    }

    .dropdown-menu {
        padding: 0.2rem 0 !important;
    }
    .tmf-dropdown__item.dropdown-item{
        gap: 0.3rem !important;
        padding: 0.5rem !important;
        padding-left: 1rem !important;
    }

    .tmf-dropdown__item__text{
        font-size: 12px !important;
    }



    .pagination-width{
        width: 9rem !important;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
    
}

/* Extra extra large devices (≥1400px) */
@media screen and (min-width: 1600px) {

    #root .c-wrapper {
        width: calc(100vw - 335px);
    }
    .pagination-width{
        width: 17rem;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
 
}