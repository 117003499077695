.leads-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    padding: 2.0025rem 2.0025rem 1.00125rem 2.0025rem;
    background-color: #fff;


    >.page-title {
        color: var(--txt-primary);
        font-size: 1.5rem;
        font-weight: 600;
    }
}