@use "variables";
@use "mixins";
.input-style {
  border-radius: variables.$border-radius;
  height: 2rem;
  border: 1px solid #c1cad1;
  font-size: 0.8rem;
  padding: 0 0.5rem;
  transition: all 0.2s ease-in-out;
  outline: 3px solid transparent;
  outline-offset: 10px;
  position: relative;
  &:focus,
  &:focus-visible {
    outline: 3px solid #03a9f426;
    border-color: #03a9f48f;
    background-color: #f3fffe;
    outline-offset: 0;
  }

  &:hover {
    //   border-color: #8bc34a;
    background-color: #4caf500d;
  }
  &::placeholder {
    font-size: 0.8rem;
    color: #7c888e;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
    &:hover {
      background-color: #c1cad1;
    }
  }
}

.form-element {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  &:focus-within {
    label {
      color: #03a9f4;
    }
  }
  label {
    margin-bottom: 0.1rem;
    font-size: 0.7rem;
    color: #7c888e;
  }
  .input {
    @extend .input-style;
  }

  .textarea {
    width: 100%;
    resize: none;
    height: auto;
  }

  .input-error-message {
    font-size: 0.75rem;
    color: #e91e63;
    margin-top: 0.2rem;
    display: flex;
    gap: 0.2rem;
    justify-content: flex-end;
    svg {
      flex-shrink: 0;
      @include mixins.size(0.8rem);
    }
    span {
      line-height: 1;
    }
  }

  &.error {
    label {
      color: #e91e63;
    }
    input {
      border-color: #e91e638f;
      background-color: #e91e630d;
    }
  }
  .input-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: variables.$border-radius;
    input {
      width: 100%;
    }
    .ltb-loader,
    .clear-input {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &:hover .clear-input {
      animation: slide-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      @keyframes slide-left {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(0);
        }
      }
      &:hover {
        background-color: #ff00000d;
        color: #e91e63;
      }
    }
    position: relative;
    .input-suffix {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      display: inline-flex;
      align-items: center;
      gap: .5rem;
    }
  }

  &.loading {
    input {
      padding-right: 2.5rem;
    }
  }

  .clear-input {
    cursor: pointer;
    transform: translateX(100%);
    svg {
      @include mixins.size(1rem);
    }
  }

  & + .form-element:last-child {
    margin-bottom: 0;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


.form-groups {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
  .form-element {
    margin-bottom: 0 !important;
    flex-grow: 1;
  }
}
