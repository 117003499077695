.dnd-wrapper{
    height: 100%;
    overflow: scroll;
}


.dnd-section{
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s linear;
    cursor: pointer;
    padding: 0.5rem;
    background-color: #f0f0f0;
    &:hover {
        background-color: #e6e6e6;
    }

    >.title-display {
        display: flex;
        gap: 6px;
        align-items: center;

        >.togglebtn {
            height: 0,8rem;
            width: 0.8rem;
            cursor: pointer;
            transition: all 0.2s linear;
            display: flex;
            align-items: center;
            justify-content: center;

            svg{
                width: 100%;
                height: 100%;
                fill: #777777;

                &:hover {
                    fill: #333;
                    transform: scaleX(1.2);
                }
            }

        

        }
    }

    >.action-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        gap: 0.5rem;
        >span{
            >svg{
                height: 1.2rem;
                width:  1.2rem;
            }
        }
    }
}

.selected-box {
    position: relative;
    background-color: #e6e6e6 !important;
    padding-left: 10px;
    transition: all 0.4s ease-in-out
}

.selected-box::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background-color: var(--secondary-color);
    z-index: 1;
    transition: all 0.2s ease-in-out
    /* Transition for width */
}

.selected-box:hover::before {
    width: 7px;
}
