@use "mixins";
@use "variables";
.avish-select {
  &__selector {
    .input-suffix {
      //   padding: 0 0.5rem;
      gap: 0 !important;
      right: 1px !important;
      top: 1px !important;
      height: calc(100% - 2px) !important;
      border-radius: variables.$border-radius;
      overflow: hidden;
    }

    &__action {
      @include mixins.flex-center();
      display: inline-flex;
      height: 100%;
      padding: 0 0.4rem;
      cursor: pointer;
      color: #99aab8;
      svg {
        @include mixins.size(1.1rem);
      }
      &:hover {
        background-color: #f0f8ff;
        color: #778590;
      }
    }
  }

  &__modal {
    &.hide-modal {
      .avish-modal__content {
        animation-duration: 0.2s;
      }
    }
    .avish-modal__content {
      animation-duration: 0.2s;
    }
    .avish-modal__body {
      padding: 0;
    }
  }
  &__search.form-element {
    margin: 0;
  }
  &__selected {
    background-color: #eaf2e0;
    height: 2rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    border-radius: variables.$border-radius;
    justify-content: space-between;
    span {
      color: #4d731e;
      font-size: 0.8rem;
    }
    &__remove {
      @include mixins.size(1rem);
      @include mixins.flex-center();
      cursor: pointer;
    }
  }
  &__options {
    list-style-type: none;
    padding: 0;
    margin-top: 1rem;
    li {
      height: 2rem;
      padding: 0 1rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: #ebf7f8;
      }
    }
    &__selected {
      background-color: #e2f6ff;
      display: flex;
      align-items: center;
      gap: 1rem;
      transition: all .2s ease;
      &:hover {
        background-color: #e2f6ff !important;
      }
      svg {
        @include mixins.size(1rem);
        color: #1190ca;
      }
    }
  }
  &__length {
    padding: 0 1rem;
    font-size: .8rem;
  }
  &__modal {
    .avish-modal__header__content {
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      gap: .5rem;
      padding: 0 1rem;
    }
  }
}
