.table-latitude {
    width: 100%;
    border: none;

    thead {
        border: none;
        border-bottom: 2px solid #eef1f3;
        th {
            text-transform: uppercase;
            text-align: center;
            padding: 10px;
            border: 1px solid #F1F1F1;
        }
    }
    tbody {
        tr {
            border-bottom: 1px solid #F1F1F1;
            color:  #75787b;
            td {
                padding: 10px;
                border: 1px solid #F1F1F1;
            }
            th {
                padding-left: 10px;
                border: 1px solid #F1F1F1;
            }
        }
    }
    tfoot {
        color: #fab700;
        font-style: italic;
        border-bottom: 2px solid #fab700;
        tr {
            td {
                padding: 10px;
                border: 1px solid #F1F1F1;
            }
        }
    }
}