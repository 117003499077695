.activity-title {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding:10px 0px;
    background-color: #fff;


    >.title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        >.page-title {
            color: var(--txt-primary);
            font-size: 1.5rem;
            font-weight: 600;
        }
    }

}

.activity-table {
    overflow-x: auto;
    margin-top: 1rem;

    .table {
        width: 100%;
        table-layout: auto;
        background-color: white;
        border-radius: 0.5rem;

        thead {
            background-color: #f9fafb;
            padding: 1rem 0;

            tr {
                th {
                    padding: 0.75rem 1.5rem;

                    text-align: center;
                    font-size: 0.75rem;
                    font-weight: 500;
                    color: #6b7280;
                    text-transform: uppercase;
                    letter-spacing: wider;
                }

                th:first-child {
                    text-align: left;
                    padding-left: 3.25rem;
                }
            }
        }

        tbody {
            border-top: 1px solid #e5e7eb;
            border-bottom: 1px solid #e5e7eb;
            cursor: pointer;

            tr {
                transition: all 0.2s ease;

                &:hover {
                    background-color: #eff6ff;
                    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
                }

                td {
                    padding: 0.7rem 1.5rem;
                    text-align: center;

                    &:first-child {
                        text-align: left !important;
                        width: 65% !important;
                        .activity-name {
                            font-size: 0.75rem;
                            font-weight: 500;
                            color: #111827;
                        }
                    }
                }
            }
        }
    }

    .source-badge {
        display: inline-block;
        padding: 0.25rem 0.5rem;
        font-size: 0.60rem !important;
        font-weight: 600;
        border-radius: 9999px;

        &--web {
            background-color: #dcfce7;
            color: #166534;
        }

        &--teams {
            background-color: #dbeafe;
            color: #1e40af;
        }

        &--outlook {
            background-color: #ebf2f7;
            color: #0078D4;
        }

        &--word {
            background-color: #f1f5fb;
            color: #204f8a;
        }

        &--default {
            background-color: #f3f4f6;
            color: #374151;
        }
    }

    .app-type-badge {
        display: inline-block;
        padding: 0.25rem 0.5rem;
        font-size: 0.60rem !important;
        font-weight: 600;
        border-radius: 9999px;

        &--webapp {
            background-color: rgba(59, 130, 246, 0.2);
            color: #3b82f6;
        }

        &--adminportal {
            background-color: #fef3c7;
            color: #d97706;
        }

        &--superadmin{
            background-color: #bd4040;
            color: #f3f4f6;
        }

        &--default {
            background-color: #f3f4f6;
            color: #374151;
        }
    }

    .activity-type-badge {
        display: inline-block;
        padding: 0.25rem 0.5rem;
        font-size: 0.60rem !important;
        font-weight: 600;
        border-radius: 9999px;

        &--login {
            background-color: #dcfce7;
            color: #166534;
        }

        &--logout {
            background-color: #fee2e2;
            color: #991b1b;
        }

        &--default {
            background-color: #f3f4f6;
            color: #374151;
        }
    }

    .table-loader {
        margin-top: 0.25rem;
        border: 2px solid #e5e7eb;
        border-radius: 0.5rem;
        text-align: center;
        height: 140px;
        padding-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .no-data {
        &--empty {
            margin-top: 0.25rem;
      
            text-align: center;
            padding: 1rem;
            border-bottom:1px solid #ddd;
            >.datatable-nocontent {
                >svg {
                    height: 120px;
                    width: 120px;
                }

                >.datatable-nocontent__message {
                    padding-top: 5px;
                    font-size: 0.8rem;
                    color: #a7a3a3;
                }
            }
        }

        &--error {
            margin-top: 0.25rem;
            border-bottom: 1px solid #ddd;

            text-align: center;
            padding: 1rem;

            >.datatable-nocontent {
                >svg {
                    height: 120px;
                    width: 120px;
                }

                >.datatable-nocontent__message {
                    padding-top: 5px;
                    font-size: 1rem;
                    color: #a7a3a3;
                }
            }
        }
    }

    .pagination {
        padding: 0 0.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}



.status-text {
    font-size: 0.70rem;
}

.status-label {
    color: #4b5563;
}

.status-label-text {
    font-weight: 500;
}

.status-timestamp {
    color: #2563eb;
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    padding:0 1.5rem;
    >.login-card {
        background-color: rgb(240, 253, 244); 
        border-radius: 0.5rem;
        padding: 1rem;
        display: flex;
        align-items: center;
        gap: 0.75rem;
        border: 1px solid rgb(220, 252, 231); 
        transition: all 0.2s ease;
    
        &:hover {
            box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
        }

        >.login-icon {
            color: rgb(22, 163, 74); // text-green-600
        }
        >.login-content {
            .login-title {
                font-size: 0.75rem;
                text-transform: uppercase;
                color: rgb(22, 101, 52); // text-green-800
                font-weight: 600;
            }
        
            .login-count {
                font-size: 1.25rem;
                font-weight: 700;
                color: rgb(22, 163, 74); // text-green-600
            }
        }
        
    }

    >.activity-card {
        background-color: #dee7ff; 
        border-radius: 0.5rem;
        padding: 1rem;
        display: flex;
        align-items: center;
        gap: 0.75rem;
        border: 1px solid #dee7ff; 
        transition: all 0.2s ease;
    
        &:hover {
            box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
        }

        >.activity-icon {
            color: #2563EB // text-green-600
        }
        >.activity-content {
            .activity-titles {
                font-size: 0.75rem;
                text-transform: uppercase;
                color: #1D4ED8; // text-green-800
                font-weight: 600;
                background-color: #dee7ff; 
            }
        
            .activity-count {
                font-size: 1.25rem;
                font-weight: 700;
                color: #2563EB; // text-green-600
            }
        }
        
    }

    >.inactive-card {
        background-color: #ffe8e8; 
        border-radius: 0.5rem;
        padding: 1rem;
        display: flex;
        align-items: center;
        gap: 0.75rem;
        border: 1px solid #ffe8e8; 
        transition: all 0.2s ease;
    
        &:hover {
            box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
        }

        >.inactive-icon {
            color:  #dc2626
        }
        >.inactive-content {
            .inactive-titles {
                font-size: 0.75rem;
                text-transform: uppercase;
                color: #9b2c2c; 
                font-weight: 600;
                background-color: #ffe8e8; 
            }
        
            .inactive-count {
                font-size: 1.25rem;
                font-weight: 700;
                color:  #dc2626; 
            }
        }
        
    }
    
}

@media screen and (max-width: 1140px){
    .stats-grid {
        grid-template-columns: 1fr 1fr; 
        >.inactive-card{
            grid-column: span 2; 
        }
        >.login-card,>.activity-card {
            grid-column: span 1;
        }
    }
}

@media screen and (max-width: 635px){
    .stats-grid {
        grid-template-columns: 1fr ; 
        >.login-card,>.activity-card, >.inactive-card {
            grid-column: span 1;
        }
    }
}



