/* .tmf-datatable__wrapper div div span svg{
    display: none !important;
} */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

*,
:root {
  font-family: "Poppins", sans-serif !important;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

body {
  background-color: #f0f2f5;
}

/* .hideScrollbar::-webkit-scrollbar {
  width: 0;
} */



::-webkit-scrollbar {
    height: 5px !important;
}


/* pagination  */
.css-wjh20t-MuiPagination-ul {
    gap: 0.4rem !important;
}

/* switch  */
.c-switch-slider {
    border: 2px solid red;

}

.c-switch-label .c-switch-slider::after {
    color: red;
}

.c-switch-label.c-switch-sm {
    width: 33px;
    height: 19px;

}

.c-switch-slider::before {
    background-color: red;
}

.c-switch-label.c-switch-sm .c-switch-slider::before {
    width: 12px;
    height: 12px;
}

.c-switch-label.c-switch-sm .c-switch-slider::after {
    font-size: 6px;
}

.c-switch-label.c-switch-sm .c-switch-input:checked~.c-switch-slider::before {
    transform: translateX(14px);
}


.table-datas {
    display: flex;
    justify-content: space-between;
}

.page {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.containers {
    margin-top: 10px;
    max-height: 640px;
    overflow-y: auto;
    font-size: 14px;
    font-weight: 400;
    color: var(--txt-primary, #23005b);
}

.csv-toggle {
    border-radius: "0px 25px 25px 0px";
    border-top: " 1px solid var(--primary, #1C63B7)";
    border-right: "1px solid var(--primary, #1C63B7)";
    border-bottom: "1px solid var(--primary, #1C63B7)";
    height: "40px";
    margin-left: "0 !important";
}

.flex {
    display: flex;
    justify-content: space-between;
}

.datatable-container {
    min-width: 1000px;
}

.datatable-wrapper {
    width: 100%;
    overflow-x: auto;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show {
    background: white !important;
}

.dropdown-sidebar-item{
    transition: all 0.2s ease-in-out;
    opacity: 1;
}

.display-none{
    opacity: 0 !important;
    transform: translateY(-50%);
}

.sidebar-item{
    position: relative !important;
}



.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle::after{
    background-image: none !important;
}



.dropdown-toggle-icon {
    font-size: 10px;
    position: absolute;
    top: 17px;
    right: 23px;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    color: var(--txt-primary, #23005b);
}

