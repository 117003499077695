@use "./mixins.scss";
@use "./variables";

.tmf-dropdown {
  .dropdown-menu {
    box-shadow: variables.$shadow-elevation-medium;
    overflow: hidden;
  }

  &__item.dropdown-item {
    @include mixins.flex-center();
    justify-content: flex-start;
    gap: 1rem;

    &:active {
      background-color: rgba(variables.$primary-color, 9%);
      color: variables.$primary-color;
    }

    &__icon {
      @include mixins.flex-center();

      i {
        font-size: 0.9rem;
      }
    }

    &__text {
      font-size: 0.8rem;
    }

    opacity: 0;
    transform: translateY(-50%);

    @for $i from 1 to 10 {
      &:nth-child(#{$i}) {
        animation: animate-dropdown-item 0.2s linear forwards (0.03s + $i * 0.02);
      }
    }

    @keyframes animate-dropdown-item {
      from {
        opacity: 0;
        transform: translateY(-50%);
      }

      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}


.dropdownmenubox {
  z-index: 200;
  transform: translateY(-10px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  position: absolute;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 8px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: white;
  margin-top: 5px;
  border-radius: 8px;
  min-width: 150px;

  >span {
    font-size: 14px;
  }

  >div {
    >div {
      font-size: 14px;
    }
  }

}

.dropdownmenubox.show {
  opacity: 1;
  transform: translateY(0);
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdownmenubox.hide {
  opacity: 0;
  transform: translateY(-10px);
  animation: slideUp 0.5s ease-out;
}

@keyframes slideUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}


//Profile Dropdown
.profile {
  position: absolute;
  z-index: 1;
  background-color: white;
  right: 0px;
  top: -14px;
  border-radius: 20px;
  transition: all 0.4s ease;
  overflow: hidden;

  >.profile-title {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    >.profile-name{
      display: flex;
      gap: 15px;
      align-items: center;
      >span{
        line-height: 24px;
        color: black;
      }
    }
    >svg{
      cursor: pointer;
    }
  }
  >.profile-desc{
    font-size: 14px;
    font-weight: 600;
    display: flex;
    line-height: 21px;
    flex-direction: column;
    gap: 5px;
    color: #000;
    padding: 20px 15px 0px 10px;
  }

  >.social-media-links{
    display: flex;
    justify-content: space-around;
    gap: 10px;
    padding: 10px;
    >a{
      height: 40px;
      width: 40px;
      background: #2D059F;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      svg{
        color: white;
        width: 25px;
        height: 25px;
        padding-right: 2px;
      }
    }
  }

  >.info{
    color: blue;
    display: flex;
    gap: 18px;
    font-size: 12px;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    >a{
      >small{
        font-size: 12px;
        line-height: 18px;
      }
    }
    >.logout-btn{
      border: none;
      outline: none;
      background: #fff;
      text-decoration: underline;
      color: #828282;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.red{
  background-color: red !important;
}

.light-blue{
  background-color: rgb(22, 139, 246) !important;
}

.dark-blue{
  background-color: rgb(7, 83, 154) !important;
}