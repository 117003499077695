.scroll-vertical,
.scroll-horizontal,
textarea {
  overflow-y: auto;
  &.scroll-overlay {
    overflow-y: overlay !important;
  }
  transition: all 0.2s ease;
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar {
   background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 2px;
    
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #8b8686;
  }
  &:hover {
    &::-webkit-scrollbar {
      width: 3px;
    }
  }
}
.scroll-horizontal {
  overflow-x: auto;
  &.scroll-overlay {
    overflow-x: overlay !important;
  }
  &::-webkit-scrollbar {
    height: 3px ;
  }
  &:hover {
    &::-webkit-scrollbar {
      height: 3px;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .scroll-vertical,
  .scroll-horizontal,
  textarea {
    &.scroll-overlay {
      overflow-y: auto !important;
    }
  }
}


//all page title css
.title {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 2.0025rem 2.0025rem 1.00125rem 2.0025rem;
  background-color: #fff;

  >.page-title {
    color: var(--txt-primary);
    font-size: 1.5rem;
    font-weight: 600;
  }

  >div {
    >.create {
      font-size: 0.875rem;
      font-weight: 600;
      color: var(--txt-primary);
      border: none;
      background-color: transparent !important;
      outline: none !important;
      transition: text-shadow 0.3s ease;

      &:hover {
        text-shadow: 0 0 0px var(--txt-primary), 0 0 0px var(--txt-primary), 0 0 20px var(--txt-primary);
      }
    }
  }
}


.modal.show .modal-dialog {
  height: 100vh !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 0 !important; 
}

.modal-content {
  border-radius: 25px !important;
}


