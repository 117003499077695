.court {
    display: flex;
    gap: 10px;
    padding: 12px;

    .category-page {
        width: 40%;
    }

    .link-page {
        width: 60%;
        min-height: 45vh !important;

        >.court-table {
            display: flex;
            overflow-y: scroll;
            flex-direction: column;
            height: 86%;
            padding: 0.7rem;

            &::-webkit-scrollbar {
                width: 3px !important;
            }

            >.table-responsive {
                &::-webkit-scrollbar {
                    width: 3px !important;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #888;
                    border-radius: 5px;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
            }

        }

    }

    >.category-page,
    >.link-page {
        max-height: 89vh;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 25px;
        z-index: 99;

        >.cat-page {
            border-radius: 20px;
            padding: 1.00125rem 2.0025rem 1.00125rem 2.0025rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            >.court-title {
                display: flex;
                gap: 8px;
                align-items: center;

                background-color: #fff;

                >h1 {
                    color: var(--txt-primary);
                    font-size: 1.5rem;
                    font-weight: 600;
                    margin-bottom: 0px !important;
                }

                >p {

                    color: var(--txt-primary);
                    font-size: 1rem;
                    font-weight: 400;
                    margin-bottom: 0px !important;

                }
            }

            >.link-setting {
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: center;

                >.pagination-box {
                    padding: 0px !important;
                }
            }

        }

        >.dnd-box {
            overflow: hidden;
        }
    }
}

.court-link-title {
    >span {
        display: flex;
        align-items: center;
        gap: 1rem;

        >img,
        >.image {
            height: 2.1rem;
            width: 2.1rem;
            border: 2px solid #d7cdcd;
            padding: 2px;
            border-radius: 3px;
        }

        >a {
            color: black;

            >p {
                font-size: 12px;
                color: black;
                margin: 0px !important;

            }
        }
    }
}