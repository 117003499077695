.tmf-filter {
  background-color: #fff;

  &__toggle-button {
    // height: 3rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    &__filter {
      background: hsla(331, 75%, 42%, 1);
      background: linear-gradient(315deg, hsla(331, 75%, 42%, 1) 43%, hsla(357, 40%, 71%, 1) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 1.2rem;
    }

    span {
      display: flex;
      flex-grow: 1;
    }

    &__arrow {
      font-size: 0.6rem;
      font-weight: 500;
    }
  }

  form {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 1rem;
    padding: 1.00125rem 2.0025rem 2.0025rem  2.0025rem;


  }

  &__submit {
    margin-top: 1.3rem;
    padding-left: 3.5rem;
  }
}

.tmf-form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    font-size: 0.7rem;
    margin: 0;
    width: 100%;
  }

  select {
    &:disabled {
      background-color: rgba($color: #000000, $alpha: 0.05);
      cursor: not-allowed;
    }
  }
}

.select-container {
  z-index: 1000;
  width: 180px;
}


.filter {
  display: flex;
  align-items: center;
  padding: 1.00125rem 2.0025rem 2.0025rem  2.0025rem;
  background-color: #fff;
  gap: 20px;
  flex-wrap: wrap;

  >div {
    label {
      color: var(--black);
      font-weight: 500;
      font-size: 16px;
      line-height: 19.5px;
      background: none;

    }

    .select-wrapper {
      position: relative;

      >svg {
        position: absolute;
        right: 3px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        color: var(--gray);
        font-size: 1.6rem;
      }


      select {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        border-radius: 4px;
        padding: 6px;
        border: none;
        outline: none !important;
        min-width: 200px;
        cursor: pointer;
        appearance: none;
        /* Removes default arrow */
        -webkit-appearance: none;
        /* Safari & Chrome */
        -moz-appearance: none;
        /* Firefox */
        -ms-appearance: none; // Edge

        &::-ms-expand {
          display: none;
        }

      }

      &::after {
        content: ''; // Empty content for custom icon
        position: absolute;
        top: 50%;
        right: 10px; // Position the icon to the right
        transform: translateY(-50%);
        width: 10px; // Adjust the size of the icon
        height: 10px;
        // background-image: url('icon-url.png'); // Replace with your icon's URL
        background-size: cover;
        pointer-events: none; // Prevent clicks on the icon
      }

    }


    >.date-range {
      position: relative;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      background-color: white;
      border: none;
      outline: none !important;
      min-width: 250px;

      >input {
        background-color: transparent;
        border: none;
        outline: none !important;
        color: var(--txt-primary);
      }

      >svg {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        font-weight: 300 !important;
        font-size: 1.6rem;
        color: var(--gray);
      }
    }
  }
}

.dateRangeRef {
  position: absolute;
  z-index: 2;
  background-color: #fff;
  padding: 12px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  margin-top: 5px;
  border-radius: 8px;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;

  div {
    fieldset {
      .tmf-form-group {
        label {
          color: var(--txt-primary);
          font-size: 0.8rem;
        }

        input {
          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
          border-radius: 4px;
          padding: 6px;
          border: none;
          outline: none !important;
          color: var(--txt-primary);
        }

      }
    }
  }
}

.dateRangeRef.show {
  opacity: 1;
  transform: translateY(0);
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.dateRangeRef.hide {
  opacity: 0;
  transform: translateY(-10px);
  animation: slideUp 0.5s ease-out;
}

@keyframes slideUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}