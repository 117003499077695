.search {
    border: 1px solid #C4C4C4;
    border-radius: 12px;
    color: "var(txt-secondary)";
    position: "absolute";
    padding: 12px 8px 9px 0px;

    >button {
        background-color: white;
        color: #434343;
        border-radius: 0px 4px 4px 0px;
        margin-top: -3px;
    }

    >input {
        height: 20px;
        border-radius: 4px 0px 0px 4px;
        outline: none;
        border: white;
        padding-right: 10px
    }
}