@import "./mixins.scss";

.ltb-loader-page {
    height: 100vh;
    width: 100vw;
    @include flex-center;
}

.skuid-loading{
    position: absolute;
    border: none;
    backdrop-filter: blur(5px); 
    height: 90%;
    right: 0.125rem; 
    top: 0.125rem;
    border-radius: 0 0.375rem 0.375rem 0; 
    padding: 0.5rem;
}

.plan-loading{
    position: absolute;
    border: none;
    backdrop-filter: blur(3px); 
    height: 80%;
    right: 0.75rem; 
    top: 0.75rem;
    border-radius: 0 0.375rem 0.375rem 0; 
    padding: 0.5rem;
}

.skuid-input{
    position: relative;
}